.welcome-container {

  h1 {
    text-align: center;
    margin-bottom: 1.5em;
  }

  .blue-container {
    color: #fff;
    background-color: palette(blue, dark);
    min-height: 200px;
    padding: 25px;
    margin-bottom: 1.5em;

    h2 {
      margin-top: 0;
    }

    .btn {
      border-radius: 0px;
      font-size: 1em;
      font-weight: bold;
      background-color: #78bd00;;
    }
  }
}

.forgot-password-container {

  margin-top: 1.5em;

  p {
    text-align: center;
  }
}