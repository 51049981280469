/* Calculate Ems (target / context) */
@function calc-em($target, $context) {
  @return ($target / $context) * 1em;
}

/* pull left/right on specified screen sizes */
@media (max-width: $screen-xs-max) {
  .pull-xs-left {
    float: left;
  }
  .pull-xs-right {
    float: right;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .pull-sm-left {
    float: left;
  }
  .pull-sm-right {
    float: right;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .pull-md-left {
    float: left;
  }
  .pull-md-right {
    float: right;
  }
}

@media (min-width: $screen-lg-min) {
  .pull-lg-left {
    float: left;
  }
  .pull-lg-right {
    float: right;
  }
}