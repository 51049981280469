@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/webfonts/ProximaNova-Regular-Full.eot');
  src: url('/assets/webfonts/ProximaNova-Regular-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-Regular-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Regular-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-Regular-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-Regular-Full.svg#wf') format('svg');
  unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/webfonts/ProximaNova-Regular.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Regular.woff') format('woff');
  unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-style: normal;
  src: url('/assets/webfonts/ProximaNova-Bold.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Bold.woff') format('woff');
  unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-style: normal;
  src: url('/assets/webfonts/ProximaNova-Bold-Full.eot');
  src: url('/assets/webfonts/ProximaNova-Bold-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-Bold-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Bold-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-Bold-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-Bold-Full.svg#wf') format('svg');
  unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: italic;
  src: url('/assets/webfonts/ProximaNova-RegularIt.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-RegularIt.woff') format('woff');
  unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  font-style: italic;
  src: url('/assets/webfonts/ProximaNova-RegularIt-Full.eot');
  src: url('/assets/webfonts/ProximaNova-RegularIt-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-RegularIt-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-RegularIt-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-RegularIt-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-RegularIt-Full.svg#wf') format('svg');
  unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-style: italic;
  src: url('/assets/webfonts/ProximaNova-BoldIt.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-BoldIt.woff') format('woff');
  unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: bold;
  font-style: italic;
  src: url('/assets/webfonts/ProximaNova-BoldIt-Full.eot');
  src: url('/assets/webfonts/ProximaNova-BoldIt-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-BoldIt-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-BoldIt-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-BoldIt-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-BoldIt-Full.svg#wf') format('svg');
  unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

$font-stack: "ProximaNova", Arial, sans-serif;

body {
  font-family: $font-stack;
  color: palette(grey, x-dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5 {
  font-weight: bold;
}
h1 {
  margin-bottom: calc-em(10, 38);
}
h2 {
  font-size: calc-em(24, 16);
}
h3 {
  font-size: calc-em(20, 16);
  font-weight: 600;
  // semibold
  margin: calc-em(50, 24) 0 calc-em(30, 24);
}
h4 {
  font-size: calc-em(20, 16);
}
h5 {
  font-size: calc-em(18, 16);
}
.title-light {
  font-weight: normal;
  font-size: calc-em(28, 16);
  margin-bottom: calc-em(28, 30);
}
.strike {
  text-decoration: line-through;
}
// Main body copy
p {
  font-size: calc-em(18, 16);
  // 18/16
  margin: 0 0 calc-em(20, 18);
  // 20/16
  line-height: calc-em(22, 18);

  // 22/16

  @media only screen and (max-width:$bp-smartphone) {
    font-size: calc-em(16, 16);
  }
}
td p {
  font-size: 1em;
  // 18/16
}
td li {
  font-size: calc-em(16, 18);
}
td {
  line-height: calc-em(20, 16);
  // 22/16
}
@media only screen and (max-width:$bp-smartphone) {
  #article_content {
    > p:first-of-type {
      font-size: calc-em(22, 16);
    }
  }
}
// Nested  li:#article_content, .article-content {
p {
  word-wrap: break-word;
}
li p {
  font-size: 1em;
}
li li {
  font-size: 0.9em;
}

strong, b {
  font-weight: bold;
}
em, i {
  font-style: italic;
}
nav {
  a {
    color: $neutral-fill;
  }
}
a {
  color: palette(blue, dark);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
input, textarea {
  font-family: $font-stack;
  font-size: calc-em(16, 16);
}

/* ----- Global */
.cat-hint {
  color: #A9A9A9;
  font-size: 1.25em;
  font-weight: normal;
  margin: 0 0 0.625em;
  // 16 / 10
}

.block-contrast {
  color: $block-contrast;
}
.tal-center, .tal_center {
  text-align: center;
}
.tal-right, .tal_right {
  text-align: right;
}
.tal-left, .tal_left {
  text-align: left;
}

/* ----- AREA SPECIFIC */

/* - Collection */
.collection, .collection-competencies {
  font-size: 0;

  li {
    font-size: 16px;

    h2 {
      margin: 0.625em 0 0.325em;
    }
    &.item {
      h2 {
        font-size: 1.25em;
        // 20/16
      }
      h3 {
        font-size: 1em;
        font-weight: normal;
      }
      p {
        color: palette(grey, x-dark);
        font-size: 1em;
        font-weight: normal;
        line-height: 1em;
      }
    }
  }
}

/* - Collection: Portrait */
.collection.portrait {
  li {
    h2 {
      font-size: 1em;
      margin: 0 0 0.325em;
    }
    cite {
      color: #a9a9a9;
      display: block;
      margin-bottom: 0.325em;
    }
    .duration {
      font-weight: normal;
      margin-bottom: 0.325em;
    }
    p {
      font-size: 1em;
      line-height: 1.1em;
    }
    &:first-child {
      h2 {
        font-size: 20px;
      }
    }
  }
}
.collection.no-feature {
  li {
    h2 {
      font-size: 1.25em;
    }
  }
}
footer {
  .quick-links {
    h4 {
      color: palette(grey, dark);
    }
  }
}
// ---- Category Page
@media only screen and (min-width:39.125em) {
  // Portrait tablet+
  .category--header {
    h1 {
      font-size: calc-em(38, 16);
    }
    h2 {
      font-size: calc-em(24, 16);
    }
  }
  .category--reslist, .competencies--reslist {
    .section-header {
      font-size: calc-em(22, 16);
      height: calc-em(40, 22);
      line-height: calc-em(40, 22);
      margin-bottom: calc-em(20, 22);
      text-indent: calc-em(27, 22);
    }
    .cat-subheader {
      font-size: calc-em(18, 16);
      text-indent: calc-em(30, 20);
    }
  }
}
@media only screen and (max-width:39.063em) {
  // Smartphones
  .category--header {
    h1 {
      font-size: calc-em(28, 16);
    }
    h2 {
      font-size: calc-em(18, 16);
    }
  }
  .category--reslist, .competencies--reslist {
    .section-header {
      font-size: calc-em(18, 16);
      height: calc-em(30, 18);
      line-height: calc-em(30, 18);
      margin: 0 calc-em(20, 18) calc-em(20, 18);
      text-indent: calc-em(20, 18);
    }
    .cat-subheader {
      font-size: calc-em(16, 16);
      text-indent: calc-em(20, 16);
    }
  }
}

/* - Article Page */
.article-titles {
  h1 {
    font-size: calc-em(38, 16);
  }
  h2 {
    font-size: calc-em(24, 16);
    font-weight: 600;
    // semibold
    margin: 0;
  }
  // < 625 Smartphone
  // this must remain below the corresponding tablet - desktop font sizes
  @media only screen and (max-width:$bp-smartphone) {
    h1 {
      font-size: calc-em(32, 16);
    }
    h2 {
      font-size: calc-em(22, 16);
    }
  }
}
// For legacy --
#article_content, .article, .article-content {
  h1 {
    font-size: calc-em(38, 16);
  }
  h2.sub {
    font-size: calc-em(24, 16);
    font-weight: 600;
    // semibold
    margin: 0;
  }
  h2.sub_nobor {
    font-size: calc-em(24, 16);
    font-weight: 600;
    // semibold
    margin: 0 0 calc-em(10, 24);
  }
  h2.subnobor {
    font-size: calc-em(24, 16);
    font-weight: 600;
    // semibold
    margin: 0;
  }
  h2.heading_tag {
    font-size: calc-em(24, 16);
    font-style: italic;
    font-weight: normal;
    margin: calc-em(10, 24) 0 0;
  }
  > h4 {
    font-size: calc-em(18, 16);
    margin-bottom: calc-em(10, 18);
  }
  // < 625 Smartphone
  // this must remain below the corresponding tablet - desktop font sizes
  @media only screen and (max-width:$bp-smartphone) {
    h1 {
      font-size: calc-em(32, 16);
    }
    h2.sub {
      font-size: calc-em(22, 16);
    }
  }
}
.author {
  p {
    font-size: calc-em(11, 16);

    span {
      font-size: calc-em(14, 12);
      font-weight: 600;
      // semibold
    }
  }
  // < 625 Smartphone
  // this must remain below the corresponding tablet - desktop font sizes
  @media only screen and (max-width:$bp-smartphone) {
    p {
      font-size: calc-em(14, 16);

      span {
        font-size: calc-em(16, 14);
      }
    }
  }
}
#article_content, .article-content, .article, .lb-content {
  > h2 {
    font-size: calc-em(28, 16);
    font-weight: 600;
    // semibold
    margin: calc-em(50, 24) 0 calc-em(30, 24);
  }
  h2 {
    font-size: calc-em(28, 16);
    font-weight: 600;
    // semibold
    margin: calc-em(50, 24) 0 calc-em(30, 24);
  }
  h3 {
    font-size: calc-em(24, 16);
  }
  h4 {
    font-size: calc-em(20, 16);
  }
  h5 {
    font-size: calc-em(18, 16);
  }
  ul, ol {
    font-size: calc-em(18, 16);
    line-height: calc-em(24, 18);

    @media only screen and (max-width:$bp-smartphone) {
      font-size: calc-em(16, 16);
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    margin-bottom: calc-em(15, 18);

    @media only screen and (max-width:$bp-smartphone) {
      margin-bottom: calc-em(15, 16);
    }
  }
  .media-element {
    margin: calc-em(30, 16) 0;
  }
}
.grey_block {
  h2, h3 {
    font-size: calc-em(28, 16);
    font-weight: normal;
    margin: 0 0 calc-em(20, 28);
  }
  p {
    &:last-of-type {
      margin: 0;
    }
  }
  h2:first-child {
    margin-top: 0 !important;
  }
}
// Lightbox Utility Page Specific
.lb-content {
  h1 {
    font-size: calc-em(38, 16);
    font-weight: bold;
    margin-bottom: calc-em(40, 38);
  }
}