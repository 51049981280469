input.form-control {

    color: black;
    border-radius: 0;

    &:focus {
        border: 1px solid #ccc;
        box-shadow: none;
    }
}

button.btn-primary {
    &:hover {
        border-color: palette(blue, dark);
    }
}

.panel {
    border: 0;

    .panel-heading {
        color: white;
        background-color: palette(blue, dark);
        border-radius: 0;
        font-weight: bold;
        border-left: 1px solid palette(blue, dark);
        border-right: 1px solid palette(blue, dark);
        border-bottom: 1px solid palette(blue, dark);
    }

    .panel-body {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .btn-primary {
        background-color: palette(blue, dark);
        border-radius: 0;
        font-weight: bold;
    }
}