.navbar-static-top {
    padding: 1.5em 0 1.5em 0;
    background-color: white;
}

.logo-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    max-width: 250px;
    max-width: 250px;
    width: 100%;
    padding: 0.5em;
}