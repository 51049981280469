//colors
@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}
// Use
// a { color:palette(purple, light); }
$palettes: (
        blue: (
                base:#0070bd,
                light:#1aace8,
                dark:#0861a2,
                x-dark:#025a96
        ),
        green: (
                base:#78bd00,
                dark:#72b203,
                x-dark:#5a9716
        ),
        yellow: (
                light: #fff3a2      // errors font color
        ),
        red: (
                medium:#ff0000        // error message boxes
        ),
        white: (
                base:#fff
        ),
        grey: (
                x-light:#fbfbfb,
                light:#f2f2f2,
                medium:#e5e5e5,
                ic-light:#c3c3c3,
                ic-dark:#b2b9bd,
                dark:#666,
                x-dark:#333
        )
);










// Need to be redefined and removed - these were in place as placeholders during early prototyping
$border-color:#a9a9a9;
$fill-color:#025e9d;
$fill-light-color:#00add8;
$fill-dark-color:#00335a;
$fill-color-mid:#084876;









// -- Global

// Font Colouring
$mid-grey:#666; // Mid-grey
$dark-grey:#333; // Dark Grey
$copy-contrast:#fff; // White
$block-contrast:#fff; // White

$stroke:#ccc; // Mid-grey

// -- Corporate

// Links
$primary-link:#0070bd; // Vibrant Blue



$primary-fill:#0070bd; // Vibrant Blue
$primary-fill-offset:#1aace8; // Baby Blue
$primary-fill-offset-dark:#0861a2; // Dark Blue

$secondary-fill:#78bd00; // Vibrant Green
$secondary-fill-offset:#72b203; // Vibrant Green Contrast
$secondary-fill-offset-offset:#5a9716; // Vibrant Green Contrast Contrast

$neutral-fill:#fbfbfb; // Very very light grey
$shade-fill:#f2f2f2; // Very light grey

$secondary-copy:#5a9716; // Vibrant Green





.bg-contrast {
  background:$secondary-fill;
  color:$neutral-fill;
}

.bg-light {
  background:$fill-light-color;
  color:$neutral-fill;
}

.low-contrast {
  background:$shade-fill;
}


.high-contrast {
  background:$primary-fill;
  color:$block-contrast;

  .heading, .section, .date {
    color:$copy-contrast;
  }
}
.light-contrast {
  background:$neutral-fill;

  a {
    color:$secondary-copy;
  }
}


.high-contrast.opt1 {
  background:$primary-fill;
  color:$block-contrast;
}
.high-contrast.opt2 {
  background:$primary-fill-offset;
  color:$block-contrast;
}